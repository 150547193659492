import React from "react"
import Accordion from "react-bootstrap/Accordion"
import Card from "react-bootstrap/Card"

import FaqLayout from "../components/faq-layout"

export default () => (
  <FaqLayout subMenuActivo="alta">
    <Accordion defaultActiveKey="0">
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="0">
          <h6>
            <a className="collapsed" href="#a" data-toggle="collapse">
              ¿Cómo solicitar el seguro online?
            </a>
          </h6>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <p>
              El proceso es muy simple. <br />
              Solo vas a requerir datos personales, datos del auto y un medio de pago electrónico
              para generar una solicitud de forma instantánea en la web de webdelseguro.com.
            </p>
            <p className="mb-0">
              Web del seguro es un Broker de seguros regulado por la Superintendencia de Seguros la
              Nación con número de matrícula nro.78421, habilitado para manejar en todo el
              territorio argentino.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="1">
          <h6>
            <a className="collapsed" href="#b" data-toggle="collapse">
              ¿Solicitar una póliza online es seguro?
            </a>
          </h6>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            <p>Es completamente seguro, esto se debe a dos razones primordiales:</p>
            <ul className="mb-0">
              <li>
                La primera es que la web es un sitio seguro con certificado SSL
                https://webdelseguro.com.ar/ donde los datos son encriptados y tratados con los
                máximos estándares de seguridad.
              </li>
              <li>
                La segunda se debe a que el único dato de medio de pago que se necesita que es la
                tarjeta de crédito, donde no será el código de seguridad, o CBU bancario, ya que no
                se está realizando una compra sino un débito automático, por lo tanto, sería
                imposible usar los datos para realizar otras transacciones.
              </li>
            </ul>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="2">
          <h6>
            <a className="collapsed" href="#b" data-toggle="collapse">
              ¿Qué necesito para solicitar la póliza online?
            </a>
          </h6>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="2">
          <Card.Body>
            <p className="mb-0">
              Requieres contar con la cédula verde del auto, tarjeta de crédito o CBU, además de la
              información personal del asegurado como número de DNI, dirección, nombre completo,
              etc. y algunas fotos actuales del auto que podrás tomar con un celular.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="3">
          <h6>
            <a className="collapsed" href="#b" data-toggle="collapse">
              ¿Puedo solicitar un seguro si tengo otro vigente?
            </a>
          </h6>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="3">
          <Card.Body>
            <p>
              Si podés hacerlo, pero es primordial que las vigencias no se superpongan, deben ser
              consecutivo.
            </p>
            <p className="mb-0">
              Para anular la póliza anterior, envía un email a la aseguradora solicitando la baja,
              para evitar superposición de cobertura.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="4">
          <h6>
            <a className="collapsed" href="#b" data-toggle="collapse">
              ¿Puedo solicitar un seguro si tengo el auto prendado?
            </a>
          </h6>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="4">
          <Card.Body>
            <p>Si, podés gestionarlo de forma anticipada.</p>
            <p className="mb-0">
              El inicio de vigencia debe ser desde el día que vence la prenda, en caso de estar
              prendado debes solicitar la liberación para contratar el seguro en aseguradoras
              autorizadas por el acreedor prendario.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  </FaqLayout>
)
